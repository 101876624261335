export const customerByEmail = async (login, token) => {
  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_MSNT_BASEURL}/query/fetch-data`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        query: `
          query { customerByEmail(email: "${login}") {  id  email name active } } `,
      }),
    });

    if (!response.ok) {
      throw new Error(response);
    }
    const data = await response.json();

    return data;
  } catch (err) {
    console.error(err);
  }
}
