const navigation = () => {
  return [
    {
      title: 'Dashboard',
      icon: 'bx:home',
      path: '/ops/dashboard',
      action: 'read',
      subject: 'client-page',

    }, {
      title: 'Playground',
      icon: 'bx:bot',
      path: '/ops/playground',
      action: 'read',
      subject: 'client-page',

    },
    {
      title: 'Chat Logs ',
      icon: 'bx:conversation',
      path: '/ops/chat-logs',
      action: 'read',
      subject: 'client-page',

    },
    {
      title: 'Analytics ',
      icon: 'bx:analyse',
      path: '/ops/analytics',
      action: 'read',
      subject: 'client-page',

    }, {
      title: 'Access Key ',
      icon: 'bx:universal-access',
      path: '/ops/access-key',
      action: 'read',
      subject: 'client-page',

    }
  ]
}

export default navigation
