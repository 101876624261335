/**
 *  Set Home URL based on User Roles
 */
const getHomeRoute = role => {
  if (role === 'UT_ADMIN') return '/dashboards/analytics'
  else if (role === 'UT_OPERATOR') return '/dashboards/analytics'
  else if (role === 'UT_ACCOUNTS') return '/dashboards/accounts'
  else if (role === 'ROLE_CUST_ADMIN') return '/ops/dashboard'
  else if (role === 'CUST_OPERATOR') return '/ops/dashboard'

  // else return '/dashboards/analytics'
}

export default getHomeRoute
