export const userByLogin = async (login, token) => {
  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_MSNT_BASEURL}/query/fetch-data`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        query: `query { userByLogin(login: "${login}") { id  name email roleNames emailConfirmed }}`,
      }),
    });

    if (!response.ok) {
      throw new Error(response);
    }
    const data = await response.json();

    return data;
  } catch (err) {
    console.error(err);
  }
}


export const userConfirmEmail = async (token, email, errorCallback) => {
  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_MSNT_BASEURL}/customers/confirm-email/${email}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (!response.ok) {
      if (errorCallback) errorCallback(data);
    }

    return data;
  } catch (err) {
    if (errorCallback) errorCallback(err);
  }
}
