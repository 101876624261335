import { AbilityBuilder, Ability } from '@casl/ability'

export const AppAbility = Ability

/**
 * Please define your own Ability rules according to your app requirements.
 * We have just shown Admin and Client rules for demo purpose where
 * admin can manage everything and client can just visit ACL page
 */

// 'read', 'create', 'update', 'delete', 'manage' <= actions
//admin-page, account-page, client-page, all <= subject
// can(actions, subject)

const defineRulesFor = (role, subject) => {
  const { can, rules } = new AbilityBuilder(AppAbility)
  if (role === 'UT_ADMIN') {
    can('manage', 'admin-page')
  } else if (role === 'UT_OPERATOR') {
    can('manage', 'admin-page')
  } else if (role === 'UT_ACCOUNTS') {
    can('manage', 'account-page')
  } else if (role === 'ROLE_CUST_ADMIN') {
    can(['manage'], 'client-page')
  }
  else if (role === 'CUST_OPERATOR') {
    can(['manage'], 'client-page')
  }

  // else {
  //   can(['read', 'create', 'update', 'delete'], subject)
  // }

  return rules
}

export const buildAbilityFor = (role, subject) => {
  return new AppAbility(defineRulesFor(role, subject), {
    // https://casl.js.org/v5/en/guide/subject-type-detection
    // @ts-ignore
    detectSubjectType: object => object.type
  })
}

export const defaultACLObj = {
  action: 'manage',
  subject: 'all'
}

export default defineRulesFor
