import Image from 'next/image';
import React from 'react';
import logo from '/public/images/logos/uhuruchat-logo.svg';

const UhuruChatVerticalLogo = () => {
  return (
    <>
      <Image src={logo} alt="UhuruChat" width={200} height={70} />
    </>
  );
};

export default UhuruChatVerticalLogo;
