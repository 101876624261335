// ** React Imports
import { createContext, useEffect, useState } from 'react'

// ** Next Import
import { useRouter } from 'next/router'

import jwt from 'jsonwebtoken'
import { customerByEmail } from 'src/hooks/useCustomer'
import { userByLogin } from 'src/hooks/useUser'


// ** Defaults
const defaultProvider = {
  user: null,
  loading: true,
  setUser: () => null,
  setLoading: () => Boolean,
  signUp: () => Promise.resolve(),
  login: () => Promise.resolve(),
  logout: () => Promise.resolve()
}
const AuthContext = createContext(defaultProvider)

const AuthProvider = ({ children }) => {
  // ** States
  const [user, setUser] = useState(defaultProvider.user)
  const [loading, setLoading] = useState(defaultProvider.loading)
  const MANAGEMENT_BASE_URL = process.env.NEXT_PUBLIC_MSNT_BASEURL;

  // ** Hooks
  const router = useRouter()
  useEffect(() => {
    const initAuth = async () => {
      setLoading(true);
      const storedToken = window.localStorage.getItem('accessToken');
      const storedUserData = window.localStorage.getItem('userData');

      const emailVerifyData = window.localStorage.getItem('verify');


      const clearLocalStorage = () => {
        localStorage.removeItem('userData');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('verify');
      };

      if (storedToken && storedUserData) {
        const decodedToken = jwt.decode(storedToken, { complete: true });
        const payload = decodedToken?.payload;
        const userInfo = await userByLogin(payload?.sub, storedToken)
        const userData = userInfo?.data?.userByLogin;
        localStorage.setItem('verify', userData?.emailConfirmed
        )
        if (!userData?.id) {
          clearLocalStorage();
          setUser(null);
          setLoading(false);
          router.replace('/login')
        } else {
          const data = JSON.parse(storedUserData)
          setUser({ ...data, verify: emailVerifyData });
          setLoading(false);
        }
      } else {
        clearLocalStorage();
        setUser(null);
        setLoading(false);
      }
    }
    initAuth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleLogin = async (params, errorCallback) => {
    try {
      const response = await fetch(`${MANAGEMENT_BASE_URL}/users/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          login: params.email,
          password: params.password,
        }),
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message);
      }

      const decodedToken = jwt.decode(data.token, { complete: true });
      const { payload } = decodedToken;
      const role = payload.roles;
      const userInfo = await userByLogin(payload.sub, data.token)
      const customerInfo = await customerByEmail(payload.sub, data.token)
      const userData = userInfo.data.userByLogin;
      const customerData = customerInfo.data.customerByEmail;
      params.rememberMe
        ? window.localStorage.setItem('verify', userData?.emailConfirmed
        )
        : null
      params.rememberMe
        ? window.localStorage.setItem('accessToken', data.token)
        : null
      const returnUrl = router.query.returnUrl
      setUser({ ...userData, role: role, token: data.token, customer: customerData, emailConfirmed: userData?.emailConfirmed, verify: userData?.emailConfirmed })
      params.rememberMe ? window.localStorage.setItem('userData', JSON.stringify({ ...userData, role: role, token: data.token, customer: customerData })) : null
      const redirectURL = returnUrl && returnUrl !== '/' ? returnUrl : '/'
      router.replace(redirectURL)
    } catch (err) {
      if (errorCallback) errorCallback(err)
    }
  }


  const handleLogout = () => {
    setUser(null)
    window.localStorage.removeItem('userData')
    window.localStorage.removeItem('accessToken')
    router.push('/login')
  }

  const handleRegistration = async (params, errorCallback) => {
    try {
      const response = await fetch(`${MANAGEMENT_BASE_URL}/customers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: params.name,
          email: params.email,
          password: params.password,
        }),
      });
      params.rememberMe = true;
      if (response.ok) {
        handleLogin(params, errorCallback)
      } else {
        const errorData = await response.json();
        if (errorCallback) errorCallback(errorData);
      }
    } catch (err) {
      console.log(err);
      if (errorCallback) errorCallback(err);
    }
  };

  const values = {
    user,
    loading,
    setUser,
    setLoading,
    login: handleLogin,
    logout: handleLogout,
    signUp: handleRegistration
  }

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }

